import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loja from '~/components/Loja';
import {
  BuscaParceiro,
  InputAsyncSelect,
  InputDate,
  InputNumber,
  InputSelect,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { useEmissaoNFE } from '~/pages/EmissaoNFE/EmissaoNFEContext';
import { ListaDeCFOP } from '~/pages/EmissaoNFE/protocols';
import api from '~/services/api';

const MySwal = withReactContent(Swal);

export const Capa: React.FC = () => {
  const {
    codLoja,
    setCodLoja,
    loja,
    changeLoja,
    setParametrosLoja,
    changeParceiro,
    formNFE: {
      register,
      formState,
      control,
      getValues,
      setValue,
      clearErrors,
      watch,
    },
    setIsBonificado,
    formItens: { setValue: setValueFormItens },
    isUpdate,
    setProdutos,
    produtos,
    setCuponsReferenciados,
    setListaDeCfop,
    formFinanceiro: {
      setValue: setValueFinanceiro,
      getValues: getValuesFinanceiro,
    },
  } = useEmissaoNFE();
  const dataEmissao = watch('dta_emissao');
  const dataSaida = watch('dta_saida');

  useEffect(() => {
    if (dataEmissao !== '' && dataSaida !== '') {
      /**
       * Ao selecionar as datas o input Finalizadora recebe seu próprio valor, pois o componente de calculo de condicao
       * acompanha em tempo real o valor do componente finalizadora, fazendo assim com que seja forçado o recalculo automático
       * da data de vencimento na aba Financeiro.
       */
      setValueFinanceiro('finalizadora', getValuesFinanceiro('finalizadora'));
    }
  }, [dataEmissao, dataSaida, getValuesFinanceiro, setValueFinanceiro]);

  useEffect(() => {
    setValue('cod_especie', {
      value: 33,
      label: 'NFE - NOTA FISCAL ELETRÔNICA',
      des_doc_fiscal: 'NOTA FISCAL ELETRÔNICA',
      cod_doc_fiscal: '55',
      num_modelo_fiscal: '',
      des_especie: 'NFE',
    });
  }, [setValue]);

  const getParametrosLoja = useCallback(
    async (codigo: number) => {
      const res = await api.get(`regraloja/${codigo}`);
      const { data, success, message } = res.data;

      if (!success) {
        throw new Error(message);
      }

      setParametrosLoja(data);
    },
    [setParametrosLoja],
  );

  const getCfopsByPerfil = useCallback(
    async (codPerfil: number) => {
      /**
       * Efetua busca de CFOPs relacionados
       */
      const { data } = await api.get('/emissao-nfe/cfop', {
        params: {
          cod_perfil_selecionado: codPerfil,
        },
      });
      if (data.success) {
        const options = data.data.map((cfop: ListaDeCFOP) => ({
          value: cfop.cfop,
          label: `${cfop.cfop} - ${cfop.des_cfop}`,
          ...cfop,
        }));
        setListaDeCfop(options);
      }
    },
    [setListaDeCfop],
  );

  const handleChangePerfil = useCallback(
    async (selected: any) => {
      const perfil = getValues('cod_perfil')?.value;
      if (perfil === undefined || produtos.length <= 0) {
        clearErrors('cod_perfil');
        setValue('cod_perfil', selected);
        setValueFormItens('cfop', '');
        setValueFormItens('bonificado', '');
        setIsBonificado(false);
        getCfopsByPerfil(selected.value);
        return;
      }
      const alteraPerfil = await MySwal.fire({
        title: 'Deseja alterar o perfil selecionado?',
        text: 'Todos os itens da tabela serão removidos.',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (alteraPerfil) {
        clearErrors('cod_perfil');
        setValue('cod_perfil', selected);
        setValueFormItens('cfop', '');
        setValueFormItens('bonificado', '');
        setIsBonificado(false);
        setProdutos([]);
        setCuponsReferenciados([]);
        getCfopsByPerfil(selected.value);
      }
    },
    [
      clearErrors,
      getCfopsByPerfil,
      getValues,
      produtos.length,
      setCuponsReferenciados,
      setIsBonificado,
      setProdutos,
      setValue,
      setValueFormItens,
    ],
  );

  return (
    <>
      <Separator labelText="Capa da Nota" sidePadding="0 0" />
      <Row>
        <Col
          md={6}
          sm={12}
          style={{
            marginTop: '10px',
          }}
        >
          <Loja
            selectedLoja={codLoja}
            onChangedObject={(e) => {
              changeLoja(e);
            }}
            onChange={(e) => {
              clearErrors('loja');
              setValue('loja', e);
              getParametrosLoja(Number(e));
              setCodLoja(Number(e));
            }}
            isDisabled={isUpdate}
            disabled={isUpdate}
          />
        </Col>
        <Col md={6} sm={12}>
          <BuscaParceiro
            label="Cliente"
            placeholder="Selecione..."
            name="busca_parceiro"
            register={register}
            isError={!!formState.errors.busca_parceiro}
            control={control}
            customOptions={{
              buscarPor: { cliente: true },
              fields: ['cod_cliente'],
            }}
            changeSelected={(selected: any) => {
              const { des_uf }: any = selected;
              if (!des_uf) {
                toast.warning(
                  `Endereço incompleto, atualize o cadastro do parceiro para continuar.`,
                );
              }
              changeParceiro(selected);
              clearErrors('busca_parceiro');
              setValue('busca_parceiro', selected);
            }}
            disabled={isUpdate || produtos.length > 0}
            codLoja={47}
          />
        </Col>
        <Col md={4} sm={12}>
          <InputAsyncSelect
            label="Perfil"
            maxLength={50}
            listWidth="650px"
            placeholder="Selecione..."
            name="cod_perfil"
            register={register}
            isError={!!formState.errors.cod_perfil}
            control={control}
            changeSelected={(selected: any) => {
              handleChangePerfil(selected);
            }}
            title={
              !getValues('busca_parceiro')?.des_uf || !loja?.cod_loja
                ? 'É necessário selecionar parceiro e loja'
                : ''
            }
            disabled={
              !getValues('busca_parceiro')?.des_uf ||
              !loja?.cod_loja ||
              isUpdate ||
              produtos.length > 0
            }
            api={{
              route: '/perfil-emissao-nf',
              routeParam: loja?.cod_loja,
              bodyParams: {
                flg_interestadual:
                  loja?.uf !== getValues('busca_parceiro')?.des_uf,
              },
              method: 'get',
              fields: ['cod_perfil', 'des_perfil'],
              dependsOf: ['routeParam', 'flg_interestadual'],
              searchBeforeFilter: true,
              messageForDependsOf: 'Selecine o Cliente Para Continuar.',
            }}
          />
        </Col>
        <Col md={1} sm={12}>
          <InputAsyncSelect
            label="Série"
            maxLength={50}
            placeholder="Série"
            name="cod_serie"
            register={register}
            isError={!!formState.errors.cod_serie}
            control={control}
            disabled={!loja?.cod_loja || isUpdate}
            title={!loja?.cod_loja ? 'É necessário selecionar uma loja' : ''}
            changeSelected={(selected: any) => {
              clearErrors('cod_serie');
              setValue('cod_serie', selected);
            }}
            api={{
              route: `/serienf/especie/${codLoja}/nfe`,
              method: 'get',
              fields: ['num_serie'],
              searchBeforeFilter: true,
            }}
          />
        </Col>
        <Col md={1} sm={12}>
          <InputSelect
            label="Espécie"
            maxLength={50}
            placeholder="Espécie"
            name="cod_especie"
            register={register}
            isError={!!formState.errors.cod_especie}
            control={control}
            options={[{ value: 33, label: 'NFE - NOTA FISCAL ELETRÔNICA' }]}
            disabled
            changeSelected={(selected) => {
              setValue('cod_especie', selected);
            }}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputNumber
            label="Nº NF"
            max={10000}
            maxLength={50}
            placeholder="0"
            name="num_nf"
            register={register}
            title="Registro gerado automaticamente"
            disabled
            isError={!!formState.errors.num_nf}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputDate
            register={register}
            label="Emissão"
            name="dta_emissao"
            control={control}
            disabled={isUpdate}
            isError={!!formState.errors.dta_emissao}
            typeError={formState.errors.dta_emissao?.message}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputDate
            label="Saída"
            register={register}
            name="dta_saida"
            control={control}
            disabled={isUpdate}
            isError={!!formState.errors.dta_saida}
            typeError={formState.errors.dta_saida?.message}
          />
        </Col>
      </Row>
    </>
  );
};
